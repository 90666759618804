import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import {createLogger} from 'redux-logger'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import './index.css';



import Template from './routes';
import accountReducer from './components/accounts/accountReducer';
import iaReducer from './components/iaShutDown/iaShutdownReducer';
import loginReducer from './components/login/loginReducer';
import marketReducer from './components/markets/marketReducer';
import notificationReducer from './components/notifications/notificationReducer';
import statReducer from './components/stats/statReducer';
import strategyReducer from './components/strategies/strategyReducer';
import tradeReducer from './components/trades/tradeReducer';
import indicatorReducer from './components/trades/indicatorReducer';


//Chargement des middlewares
const createStoreWithMiddleware = applyMiddleware(ReduxPromise, ReduxThunk,createLogger())(createStore);

const reducers = combineReducers(
    {
        user : loginReducer,

        iaStatus: iaReducer,
        markets: marketReducer,
        notifs: notificationReducer,
        strategies: strategyReducer,
        stats: statReducer,
        trades: tradeReducer,
        indicators: indicatorReducer,


        //Gestion des comptes
        accounts: accountReducer

    });
const store = createStoreWithMiddleware(reducers);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },
    palette: {
        primary: {
            light: '#2c3e50',
            main: '#2c3e50',
            dark: '#2c3e50',
            contrastText: '#fff',
        },
        secondary: {
            light: '#4dabf5',
            main: '#2980b9',
            dark: '#0276aa',
            contrastText: '#fff'
        },
        danger: {
            light: '#F44336',
            main: '#F44336',
            dark: '#F44336',
            contrastText: '#fff'
        }
    }
});


ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <Template />
        </MuiThemeProvider>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
