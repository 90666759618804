import Login from '../views/login/login';
import {configTradingView, login_route, tradingview_route} from '../constants';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import React from 'react';


export default
{
    Login:
        {
            component: Login,
            path: login_route
        },

    TradingView : {
        component: () => <TradingViewWidget {...configTradingView} />,
        path: tradingview_route
    }
};

