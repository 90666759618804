import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import _ from 'lodash';



import PrivateLayout from '../layouts/privateLayout';
import PublicLayout  from '../layouts/publicLayout';

import privateRoutes from './privateRoutes';
import publicRoutes  from './publicRoutes';
import history from './history';

import Login from '../views/login/login';


//TODO : En attendant de fixer le .env
class Template extends Component
{

    render()
    {
        const user = this.props.user;

        return (
            <Router history={history}>
                <Switch>

                    { _.map(publicRoutes, (route, key) =>
                    {
                        const { component, path } = route;

                        //Isolé pour ne pas avoir a faire au PublicLayout
                        if(path === "/tradingview")
                            return (
                                <Route
                                    strict exact
                                    path={path} key={key}  component={component} />)
                        if( path === "/SeDeconnecter")
                            return alert('Hello')

                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={ (route) => <PublicLayout component={component} route={route} /> }
                            />
                        )
                    }) }


                    {
                        _.map(privateRoutes, (route, key) =>
                    {
                        const { component, path, title, avatar, color } = route;

                        //TODO PrivateLayout ne doit pas rerender sur condition de user.login_success, mais plutot rediriger vers une nouvelle route : Sinon, si on raffraichit la page, on a plus l'acces
                        return (
                            <Route
                                exact
                                path={path}
                                key={key}
                                render={
                                    (route) =>
                                        user.login_success !== undefined && user.login_success === true ? (
                                            <PrivateLayout component={component} route={route} title={title} avatar={avatar} color={color} />
                                        ) : (
                                            <PublicLayout component={Login} route={route} />
                                        )
                                }
                            />
                        )
                    }) }

                </Switch>
            </Router>
        );
    }
}

function mapStateToProps({user})
{
    return {user};
}

export default connect(mapStateToProps)(Template)
