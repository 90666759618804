import history from '../../routes/history';
import { getNewTrade, updateOneTrade, closeTrade } from '../trades/tradeAction';
import { updateMarketCard } from '../trades/indicatorAction';
import { addNotification } from '../notifications/notificationAction';
import {
    loginConstants,
    login_route,
    websocketConstants,
    notificationConstants,
    jwtConstants,
    LOGIN_URL
} from '../../constants';
import axios from 'axios'
import jwt from 'jsonwebtoken'
import * as io from "socket.io-client"
export function requestLogin(loginRequest)
{

    return {
        type: loginConstants.LOGIN_REQUEST,
        payload: loginRequest
    };
}

export const login =  (username, password, callback_success, callback_error) => {

    return  function(dispatch)
    {


        const response =  axios
            .post(LOGIN_URL,
                {
                username: username,
                password: password
            },{withCredentials: true})
            .then((response) =>{
                const {jwtToken} = response.data
                dispatch(addJwtToStore(jwtToken))

                const login_success = response.data.login_success
                const idUser =
                login_success ? callback_success() : callback_error()
                const login_request = {login_success, idUser};


                dispatch(requestLogin(login_request))


            })
            .catch((error) => {
                console.log(error);
            })



    }

}

export const logout = () => {
    history.push(login_route);
    return {
        type: loginConstants.LOGOUT
    };
}



export function addJwtToStore(jwt)
{
    return {
        type: jwtConstants.AJOUT_JWT,
        payload: jwt
    }
}
export function addSocketToStore(socket)
{
    return {
        type: websocketConstants.AJOUT_SOCKET,
        payload: socket
    }
}
export function requestWebSocketConnection()
{
    return {
        type: websocketConstants.REQUEST_SOCKET
    }
}
export function webSocketConnectionSuccess()
{
    return {
        type: websocketConstants.SOCKET_SUCCESS
    }
}
export function webSocketConnectionFailure()
{
    return {
        type: websocketConstants.SOCKET_FAILURE
    }
}


//Redux-thunk
export function connectWebSocket(url)
{
    // Use of redux-thunk to return a function that dispatches the right actions.
    return (dispatch,getState) =>
    {
        //TODO: Sécuriser la websocket


        const {jwtToken} = getState()["user"]
        const socket = io.connect(url)

        socket.on('connect', () =>
        {
            console.log('Websocket connected with the server');
            dispatch(requestWebSocketConnection())
            socket.emit('handshake',{'message' :'hello'})
            // socket.send(JSON.stringify({jwtToken : jwtToken}))
        })
        socket.on(websocketConstants.BALANCE_UPDATE, (data) =>
        {
            dispatch(updateMarketCard(data))

        })

        socket.on(websocketConstants.TRADE_OPENED, (data) =>
        {
            dispatch(getNewTrade(data));
            dispatch(addNotification(notificationConstants.NEW_TRADE, data));
        })


        socket.on(websocketConstants.PRICE_UPDATE, (data) =>
        {
            dispatch(updateOneTrade(data));

        })

        socket.on(websocketConstants.ACCURATE_UPDATE, (data) => {
            dispatch(updateMarketCard(data));
        })
        socket.on(websocketConstants.STRENGTH_UPDATE, (data) =>
        {
            dispatch(updateMarketCard(data));
        })

        socket.on(websocketConstants.TRADE_CLOSED, (data) =>
        {
            dispatch(closeTrade(data));
            dispatch(addNotification(notificationConstants.CLOSED_TRADE, data));
        })

        // socket.on('message', (message) =>
        // {
        //     const data = JSON.parse(message.data);
        //
        //     switch(data.action) {
        //         case websocketConstants.TRADE_OPENED:
        //             dispatch(getNewTrade(data));
        //             dispatch(addNotification(notificationConstants.NEW_TRADE, data));
        //             break;
        //
        //         case websocketConstants.PRICE_UPDATE:
        //             dispatch(updateOneTrade(data));
        //             break;
        //
        //         case websocketConstants.ACCURATE_UPDATE:
        //             dispatch(updateMarketCard(data));
        //             break;
        //
        //         case websocketConstants.STRENGHT_UPDATE:
        //             dispatch(updateMarketCard(data));
        //             break;
        //
        //         case websocketConstants.BALANCE_UPDATE:
        //             dispatch(updateMarketCard(data));
        //             break;
        //
        //         case websocketConstants.TRADE_CLOSED:
        //             dispatch(closeTrade(data));
        //             dispatch(addNotification(notificationConstants.CLOSED_TRADE, data));
        //             break;
        //
        //         default:
        //             break;
        //     }
        // })


        socket.on('close',  (e) =>
        {
            console.log('Websocket closed ... ')
            console.log(e)
            console.log('Reconnecting the websocket')

            //TODO boucler ou pas sur la connection de websocket
            setTimeout(() =>
            {
                // RTODO Attention, c'est ici la connection
                // dispatch(connectWebSocket(url));
            },1000)
        })
        socket.on('error', (e) => {console.log('Error with the Websocket ...'); console.log(e);})

        dispatch(addSocketToStore(socket));

    }
}
