import blue from '@material-ui/core/colors/blue';

//TODO Port de l'API Server est de 3100

import {REACT_APP_SERVER_IP, REACT_APP_SERVER_IP_IA, SERVER_IP_LOCAL, SERVER_IP_REMOTE} from "./config"

export const SERVER_IP = SERVER_IP_REMOTE;
//export const SERVER_IP = SERVER_IP_LOCAL;

export const routes_menu = ['/trades', '/markets', '/strategies', '/stats', '/accounts', 'SeDeconnecter'];
export const colors_menu = [blue[500], blue[300], blue[400], blue[300], blue[500]]
export const login_route = '/';

export const tradingview_route = '/tradingview';


export const configTradingView =  {
    // "autosize": true,
    "symbol": "FOREXCOM:GRXEUR",
    "interval": "1",
    "timezone": "Europe/Paris",
    "theme": "Light",
    "style": "1",
    "locale": "fr",
    "toolbar_bg": "#f1f3f6",
    "enable_publishing": false,
    "hide_top_toolbar": true,
    "save_image": false,
}
export const loginConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT'
}

export const marketConstants = {
    GET_MARKETS: 'get_markets'
}
export const marketTypes = {
    INDEX: 'Index',
    MATIERE_PREMIERE: 'Matiere Premiere',
    FOREX: 'Forex'
}

export const strategyConstants = {
    GET_STRATEGIES: 'GET_STRATEGIES'
}

export const accountConstants = {
    GET_ACCOUNTS: 'GET_ACCOUNTS'
}

export const notificationConstants = {
    NEW_TRADE: 'NEW_TRADE',
    CLOSED_TRADE: 'CLOSED_TRADE',
    MAKE_ALL_SEEN: 'MAKE_ALL_NOTIF_SEEN'
}
export const MAX_NOTIF_HUNG = 10;


// export const TRADES_URL = 'http://' + SERVER_IP + ':3000/api/trades/1';

export const tradeConstants = {
    GET_TRADES: 'GET_TRADES',
    GET_CURRENT_TRADES: 'GET_CURRENT_TRADES',
    DISABLE_ONE_TRADE: 'DISABLE_OBE_TRADE'
}
export const trade_ticks_and_prices = {
    'FDAX': {tick: 0.5, value: 12.5, currency: 'EUR'},
    '6E': {tick: 0.00005, value: 6.25, currency: 'EUR'},
    'GC': {tick:0.1, value: 10.00, currency: 'EUR'},
    '6B': {tick: 0.0001, value: 6.25, currency: 'EUR'}
}

export const IAConstants = {
    GET_IA_STATUS_FROM_SERVER: 'GET_IA_STATUS_FROM_SERVER',
    CHANGE_STATUS: 'CHANGE_STATUS'
}

export const websocketConstants = {
    TRADE_OPENED: 'TradeOpened',
    TRADE_CLOSED: 'TradeClosed',
    PRICE_UPDATE: 'PriceUpdated',
    ACCURATE_UPDATE: 'AccurateUpdate',
    STRENGTH_UPDATE: 'StrengthUpdate',
    BALANCE_UPDATE: 'BalanceUpdate',
    LIQUIDATE: 'Liquidate',
    AJOUT_SOCKET: 'AJOUT_SOCKET',
    REQUEST_SOCKET : 'REQUEST_SOCKET',
    SOCKET_SUCCESS : 'SOCKET_SUCCESS',
    SOCKET_FAILURE : 'SOCKET_FAILURE'
}


export const jwtConstants =
    {
        AJOUT_JWT: 'AJOUT_JWT'
    }







export const statConstants = {
    GET_STATS_PERSON_TODAY: 'GET_STATS_PERSON_TODAY',
    GET_STATS_PERSON_WEEK: 'GET_STATS_PERSON_WEEK',
    GET_STATS_PERSON_MONTH: 'GET_STATS_PERSON_MONTH',
    GET_STATS_IA_TODAY: 'GET_STATS_IA_TODAY',
    GET_STATS_IA_WEEK: 'GET_STATS_IA_WEEK',
    GET_STATS_IA_MONTH: 'GET_STATS_IA_MONTH'
}





export const dev_trade = {
    MARKET: 'code',
    SIDE: 'side',
    ACCURACY: 'accurate',
    STRENGTH: 'strength',
    BALANCE: 'balance',
    OPEN_DATE: 'date1',
    QUANTITY_1: 'amount1',
    VALUE_1: 'value1',
    CLOSE_DATE: 'date2',
    QUANTITY_2: 'amount2',
    VALUE_2: 'value2',
    PROFIT: 'profit',
    STATUS: 'statut',
    LIQUIDATE: 'liquidate'
}
export const dev_trade_fields = Object.keys(dev_trade).map((x) => dev_trade[x]);

export const dev_trade_liquidate = {
    ACTION: 'action',
    MARKET: 'code',
    SIDE: 'side',
    QUANTITY_1: 'quantity',
    USER_ID: 'idUser'
}
export const dev_trade_liquidate_fields = Object.keys(dev_trade_liquidate).map(x => dev_trade_liquidate[x])

export const dev_trade_side_sell = 'SELL';
export const dev_trade_side_buy = 'BUY';

export const dev_stats_fields = ['today', 'week', 'month'];

export const dev_sort = {
    DESC: 'desc',
    ASC: 'asc'
}

export const company_name = 'ROBANK HOOD'


export const IA_STATUS_URL = 'http://' + SERVER_IP + ':3100/api/IA';
export const IA_START_URL = 'http://' + SERVER_IP + ':3100/api/IA/start';
export const IA_SHUTDOWN_URL = 'http://' + SERVER_IP + ':3100/api/IA/stop';
export const TRADES_URL = 'http://' + SERVER_IP + ':3100/api/trades';
export const LOGIN_URL = 'http://' + SERVER_IP + ':3100/api/login';
export const WEBSOCKET_URL = 'ws://' + SERVER_IP + ':3200/';
export const STATS_URL_PERSON = TRADES_URL
