import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { getAllAccounts } from './accountAction';
import { accountMessageAbonnement, used_language, titleRestrictedAction } from '../../langues';
import AccountPresentationnal from './accountPresentationnal';
import AlertDialog from '../alertDialog/alertDialog';
const io = require('socket.io-client')

const style =
    {
        paper: {
            marginTop: 30
        }
    };


class AccountContainer extends Component
{
    state = {}

    // Enable handling the alert dialog
    close = () => this.setState({open: false});
    open = (title, msg) => this.setState({open: true, title: title, message: msg});

    componentDidMount()
    {
        this.props.getAllAccounts();


    }

    handleAddAccount()
    {
        this.open(titleRestrictedAction[used_language], accountMessageAbonnement[used_language]);


    }

    render()
    {
        return (
            <div>
                <AlertDialog title={this.state.title} text={this.state.message} open={this.state.open} handleClose={this.close.bind(this)} />
                <Paper className={this.props.classes.paper} >
                    <AccountPresentationnal accounts={this.props.accounts} handleAddAccount={this.handleAddAccount.bind(this)} />
                </Paper>
            </div>
        );
    }
}

function mapStateToProps({ accounts }) {
    return { accounts };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllAccounts }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AccountContainer));
