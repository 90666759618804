import { accountConstants } from '../../constants';




// TODO: Le paylod est dur

const accounts = [
    {
        name: 'Compte de démonstration',
        func: 'Robank Hood - 100 000€',
        disabled: false,
        id: 'azeuioqsdljazepoicnvifsdf'
    }
];





export const getAllAccounts = () => {
    // TODO : get them from server
    return {
        type: accountConstants.GET_ACCOUNTS,
        payload: accounts
    }
}
