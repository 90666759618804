import { IAConstants } from '../../constants';


function iaReducer (state = {known_status: false}, action)
{
    switch(action.type)
    {
        
        case IAConstants.GET_IA_STATUS_FROM_SERVER:
            return {
                known_status: true, 
                iaStatus: action.payload.data[0].iaStatut
            };
            
        case IAConstants.CHANGE_STATUS:
            return {
                ...state,
                iaStatus: action.payload
            };

        default:
            return state;
    }
}

export default iaReducer;
