import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuDrawer from './common/menuDrawer';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IAShutdownButton from '../components/iaShutDown/iaShutdownButton';
import axios from 'axios';
import {IA_SHUTDOWN_URL, IA_SHUTDOWN_URL_LOCAL, IA_START_URL, IA_START_URL_LOCAL} from '../constants';
import { getIAStatusFromServer, changeIAStatus } from '../components/iaShutDown/iaShutdownAction';

const styles = {
    grid: {
      margin: 50,
      width: "auto"
    },
    title: {
        fontSize: 25,
        fontVariant: "small-caps"
    }
};

class PrivateLayout extends Component {
    handleClick = (e) => {
        e.preventDefault();
        this.props.iaStatus.iaStatus
            ?
            axios.get(IA_SHUTDOWN_URL,{

                withCredentials: true
            }) :
            axios.get(IA_START_URL,
                {
                withCredentials: true
            })
        this.props.changeIAStatus(!this.props.iaStatus.iaStatus);
    };

    render()
    {
        if (!this.props.iaStatus.known_status)
        {
            this.props.getIAStatusFromServer()
        }
        const Component = this.props.component;
        const { classes, route, title, avatar, color } = this.props;
        return (
            <div>
                <MenuDrawer />
                <Grid container spacing={8} direction="column" justify="space-around" className={classes.grid}>
                    <Grid item>
                        <Card>
                            <CardHeader
                            classes={{
                                title: classes.title,
                                }}
                            avatar={
                                <Avatar aria-label={title} style={{backgroundColor: color}}>
                                    {avatar}
                                </Avatar>
                            }
                            action={
                                <IAShutdownButton received_info={this.props.iaStatus.known_status} active={this.props.iaStatus.iaStatus} onclick={this.handleClick.bind(this)} />
                            }
                            title={title}
                            />
                        </Card>                
                    </Grid>
                    <Grid item>
                        <Component route={route} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps({ iaStatus }) {
    return { iaStatus };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getIAStatusFromServer, changeIAStatus }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrivateLayout));
