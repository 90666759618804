import {jwtConstants, loginConstants, websocketConstants} from '../../constants';


//LoginReducer
function loginReducer (state = {}, action)
{
    switch(action.type)
    {
        case loginConstants.LOGIN_REQUEST:  return {
                ...state,
                login_success: action.payload.login_success,
                idUser: action.payload.idUser
            };

        case loginConstants.LOGOUT:
            state.login_success = false;
            //state.socket.close();
            return state;

        case websocketConstants.AJOUT_SOCKET: return {
                ...state,
                socket: action.payload,
            }
        case jwtConstants.AJOUT_JWT: return {
                ...state,
                jwtToken : action.payload,
            };

        default:
            return state
    }
}

export default loginReducer;
