import axios from 'axios'
import {statConstants, STATS_URL_PERSON, TRADES_URL} from '../../constants';
import moment from 'moment'

const request_from_timestamp = async (url, timestamp) =>
{

    const request = await axios
        .get(TRADES_URL,{withCredentials: true })

    return request
}

// One function for each action possible
export const getStatsPersonToday = () => {
    let today = moment().startOf('day');
    // const request = request_from_timestamp(STATS_URL_PERSON, today/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, today/1000);
    return {
        type: statConstants.GET_STATS_PERSON_TODAY,
        payload: request
    }
}

export const getStatsPersonWeek = () =>
{
    let this_week = moment().startOf('day').subtract(1, 'weeks');
    // const request = request_from_timestamp(STATS_URL_PERSON, this_week/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, this_week/1000);
    return {
        type: statConstants.GET_STATS_PERSON_WEEK,
        payload: request
    }
}

export const getStatsPersonMonth = () => {
    let this_month = moment().startOf('day').subtract(1, 'months');
    // const request = request_from_timestamp(STATS_URL_PERSON, this_month/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, this_month/1000);
    return {
        type: statConstants.GET_STATS_PERSON_MONTH,
        payload: request
    }
}

export const getStatsIAToday = () => {
    let today = moment().startOf('day');
    // const request = request_from_timestamp(STATS_URL_IA, today/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, today/1000);
    return {
        type: statConstants.GET_STATS_IA_TODAY,
        payload: request
    }
}

export const getStatsIAWeek = () => {
    let this_week = moment().startOf('day').subtract(1, 'weeks');
    // const request = request_from_timestamp(STATS_URL_IA, this_week/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, this_week/1000);
    return {
        type: statConstants.GET_STATS_IA_WEEK,
        payload: request
    }
}

export const getStatsIAMonth = () => {
    let this_month = moment().startOf('day').subtract(1, 'months');
    // const request = request_from_timestamp(STATS_URL_IA, this_month/1000);
    const request = request_from_timestamp(STATS_URL_PERSON, this_month/1000);
    return {
        type: statConstants.GET_STATS_IA_MONTH,
        payload: request
    }
}
