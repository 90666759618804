import React from 'react';
import PropTypes from 'prop-types';
import SortingTable from '../table/sortingTable';
import { used_language, no_trade_label } from '../../langues';

TradePresentationnal.propTypes = {
    headers: PropTypes.array.isRequired
}

function TradePresentationnal(props) 
{
    
    const { headers, rows, order, orderBy, label_to_print } = props;
    if (rows === undefined)
    {
        // TODO : A gérer dans SortingTable
        return (
            <div>
                {no_trade_label[used_language]}
            </div>
        )
    }
    return (
        <div>
            <SortingTable headers={headers} rows={rows} order={order} orderBy={orderBy} label_to_print={label_to_print} />
        </div>
    );
}

export default TradePresentationnal;
