import axios from 'axios';
import round from 'round-to';
import {SERVER_IP, tradeConstants, TRADES_URL} from '../../constants';
import { websocketConstants, dev_trade_side_sell, trade_ticks_and_prices } from '../../constants';



export const getAllTradesFixed = async () =>
{


    const request = await axios
        .get(TRADES_URL,{withCredentials: true })
    return {
        type: tradeConstants.GET_TRADES,
        payload: request
    }
}

export const getAllCurrentTrades = (data) => {
    return {
        type: tradeConstants.GET_CURRENT_TRADES,
        payload: data
    }
}

export const disableOneTrade = (code) => {
    return {
        type: tradeConstants.DISABLE_ONE_TRADE,
        payload: code
    }
}

export const getNewTrade = (data) => {
    return {
        type: websocketConstants.TRADE_OPENED,
        payload: data
    }
}

export const closeTrade = (data) => {
    return {
        type: websocketConstants.TRADE_CLOSED,
        payload: data
    }
}

export const updateOneTrade = (data) => {
    return {
        type: websocketConstants.PRICE_UPDATE,
        payload: data
    }
}

export const tradeAddProfit = (trade) =>
{
    // Calculating the profit
    const { tick, value } = trade_ticks_and_prices[trade.code]

    trade.profit = round(value / tick * (trade.value1 - trade.value2) * trade.amount1, 2)

    trade.profit *= trade.side === dev_trade_side_sell ? 1 : -1;
    trade.profit = trade.profit === 0 ? 0 : trade.profit || undefined;

    return trade;
}
