import Trade from '../views/trades/trades';
import Market from '../views/markets/markets';
import Strategy from '../views/strategies/strategies';
import Stats from '../views/stats/stats';
import Account from '../views/accounts/accounts';
import { routes_menu, colors_menu } from '../constants';
import { used_language, titles, avatars } from '../langues';

const comps = [Trade, Market, Strategy, Stats, Account];
const labels = titles[used_language];

let privateRoutes = {};
comps.forEach(
    (component, key) => privateRoutes[labels[key]] =
        {
            component: component,
            path: routes_menu[key],
            title: titles[used_language][key],
            avatar: avatars[key],
            color: colors_menu[key]
        });




export default privateRoutes;